import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"form",staticStyle:{"padding":"0 15px","align-items":"center"},on:{"click":function($event){_vm.disabled_show_background_color = false}}},[_vm._m(0),_c('div',{staticClass:"control"},[_c(VSwitch,{attrs:{"disabled":_vm.disabled_show_background_color},model:{value:(_vm.item.show_background_color),callback:function ($$v) {_vm.$set(_vm.item, "show_background_color", $$v)},expression:"item.show_background_color"}})],1)]),(_vm.item.show_background_color)?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin":"15px 0"}},[_c('label',{staticClass:"label",staticStyle:{"min-width":"250px","margin":"0"}},[_vm._v(" Change Color ")]),_c('div',{staticClass:"control"},[_c(VMenu,{attrs:{"top":"","z-index":"130","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"custom__menu--color",style:(("background-color:" + (_vm.item.background_submenu_color)))},on))]}}],null,false,513246639),model:{value:(_vm.show_background_color),callback:function ($$v) {_vm.show_background_color=$$v},expression:"show_background_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},model:{value:(_vm.item.background_submenu_color),callback:function ($$v) {_vm.$set(_vm.item, "background_submenu_color", $$v)},expression:"item.background_submenu_color"}})],1)],1)],1)],1)]):_vm._e()]),(_vm.item.show_background_color)?_c('div',[_c('div',{staticClass:"form"},[_c('div',{staticClass:"field",staticStyle:{"margin-bottom":"10px"}},[_c('p',{staticClass:"label",staticStyle:{"margin-bottom":"15px"}},[_vm._v("Upload background image")]),_c('div',{staticClass:"control"},[_c('vue-dropzone',{ref:"upload",staticStyle:{"margin-bottom":"15px"},attrs:{"id":"upload","options":_vm.dropzoneOptions,"destroyDropzone":false},on:{"vdropzone-mounted":_vm.mountDropzone,"vdropzone-sending":_vm.customEvent,"vdropzone-removed-file":_vm.handleRemove,"vdropzone-success":_vm.handleSuccess}})],1)])]),_c('PopupCrop',{ref:"PopupCrop",attrs:{"title":"Change image before upload?"},on:{"confirm":_vm.openEditor,"save":_vm.saveImg,"close":_vm.closeCrop}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"font-weight":"normal","width":"250px"}},[_vm._v("Active Custom Background")])])}]

export { render, staticRenderFns }